import { create } from 'zustand';
import { fetchUsage, getOrCreateUser, acceptTOS as acceptTOSApi, upgradePlan as upgradePlanApi, saveMicrophoneStatus as saveMicrophoneStatusApi, updateUserPreferences } from '../api/user';
import { analytics } from '../util/analytics';
const useUserStore = create((set, get) => ({
  user: null,
  accountDetails: null,
  isAccountLoading: false,
  accessToken: null,
  isLimitReached: false,
  showTOS: false,
  shouldSaveTranscript: true,

  setAccessToken: (token) => set({ accessToken: token }),

  setUser: (user) => set({ user }),

  fetchAccountDetails: async () => {
    const { accessToken } = get();
    if (accessToken) {
      set({ isAccountLoading: true });
      try {
        const data = await fetchUsage(accessToken);
        set({ 
          accountDetails: data, 
          isAccountLoading: false,
          isLimitReached: data.usageRemaining <= 0.05
        });
      } catch (err) {
        console.error('Error fetching usage:', err);
        set({ isAccountLoading: false });
      }
    }
  },

  initializeUser: async (auth0User, referralCode) => {
    const { accessToken } = get();
    if (accessToken && auth0User) {
      try {
        const user = await getOrCreateUser(accessToken, auth0User, referralCode);
        analytics.identify(user._id);
        set({ 
          user,
          showTOS: !user.hasAcceptedTOS,
          shouldSaveTranscript: user.shouldSaveTranscript ?? true
        });
        return user;
      } catch (error) {
        console.error('Error initializing user:', error);
      }
    }
  },

  acceptTOS: async () => {
    const { accessToken } = get();
    try {
      const response = await acceptTOSApi(accessToken);
      if (response.message === 'TOS accepted successfully.') {
        set(state => ({ 
          user: { ...state.user, hasAcceptedTOS: true },
          showTOS: false
        }));
      }
    } catch (error) {
      console.error('Error accepting TOS:', error);
    }
  },

  upgradePlan: async (plan) => {
    const { accessToken } = get();
    try {
      return await upgradePlanApi(accessToken, plan);
    } catch (error) {
      throw error;
    }
  },

  saveMicrophoneStatus: async (status) => {
    const { accessToken } = get();
    try {
      return await saveMicrophoneStatusApi(accessToken, status);
    } catch (error) {
      throw error;
    }
  },

  toggleSaveTranscript: async () => {
    const { accessToken, shouldSaveTranscript } = get();
    const newValue = !shouldSaveTranscript;
    try {
      set({ shouldSaveTranscript: newValue });
      await updateUserPreferences(accessToken, { shouldSaveTranscript: newValue });
    } catch (error) {
      console.error('Error updating transcript saving preference:', error);
      set({ shouldSaveTranscript: !newValue });
    }
  },

  setIsLimitReached: (value) => set({ isLimitReached: value }),
}));

export default useUserStore;
