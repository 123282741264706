import _ from 'lodash';

import priyaPersona from './persona';
import priya_thumbnail from '../../assets/images/dr_priya_thumbnail.png';

let priyaStateChangesSinceCum = 0;

export const priya = {
  personaConfig: priyaPersona,
  thumbnail: priya_thumbnail,
  defaultState: {
    isPriyaNaked: false,
    position: 'sitting',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newPriyaState = { ...prevState };
  
    if (detail.take_off_priya_clothes && !prevState.isPriyaNaked) {
      newPriyaState.isPriyaNaked = true;
    }

    if (detail.position && detail.position !== prevState.position) {
      newPriyaState.position = detail.position;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newPriyaState.cumLocations = [...newPriyaState.cumLocations, detail.cum_location];
    }

    if (
      newPriyaState.isPriyaNaked !== prevState.isPriyaNaked ||
      newPriyaState.position !== prevState.position ||
      newPriyaState.cumLocations.length > prevState.cumLocations.length
    ) {
      priyaStateChangesSinceCum++;
      if (priyaStateChangesSinceCum > 2 && !(newPriyaState.cumLocations.length > prevState.cumLocations.length)) {
        newPriyaState.cumLocations = [];
        priyaStateChangesSinceCum = 0;
      }

      return newPriyaState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isPriyaNaked ? `I'm naked` : `I still have my scrubs on`;
    let positionStateDescription =
      sceneState.position === 'examining_patient' ? `I'm examining his genitals` :
      sceneState.position === 'sitting' ? `I'm sitting in my chair` :
      sceneState.position === 'titfuck' ? `I have his white penis between my boobs` :
      sceneState.position === 'giving_handjob' ? `I'm giving him a handjob` :
      sceneState.position === 'giving_blowjob' ? `I'm giving him a blowjob` :
      sceneState.position === "show_pussy_frontal" ? `I'm showing him my pussy` :
      sceneState.position === "show_pussy_closeup" ? `I'm showing him a closeup my pussy` :
      sceneState.position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.position === "show_pussy_from_behind" ? `I'm showing him my pussy from behind` :
      sceneState.position === "sex_reverse_cowgirl" ? `I'm having sex with him in reverse cowgirl position, riding him` :
      sceneState.position === "face_closeup" ? `my face is close up to his` :
      sceneState.position === 'sex_missionary' ? `we're having sex in missionary position` :
      sceneState.position === 'sex_doggystyle' ? `we're having sex in doggystyle position` :
      sceneState.position === 'show_butt' ? `I'm standing in front of him, showing him my butt` :
      ''
      return `I should keep the conversation going, continuing the current thread and keeping in mind that ${clothingStateDescription} and ${positionStateDescription}`;
  }
}

export default priya;