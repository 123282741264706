import React from 'react';
import { closeModal } from '../../stores/modalStore';
import { analytics } from '../../util/analytics';

const ReportModal = ({ imageId }) => {
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      analytics.track('Closed Report Modal');
      closeModal('report');
    }
  };

  const onClose = () => {
    analytics.track('Closed Report Modal');
    closeModal('report');
  };

  const handleReport = () => {
    analytics.track('Clicked Report Content Button');
    const today = new Date().toISOString().split('T')[0];
    const subject = encodeURIComponent(`Content Reporting ${today}`);
    const body = encodeURIComponent(
      `Current image ID = ${imageId}\n\n` +
      `Please provide details about the content you're reporting:\n\n` +
      `1. Description of the content:\n\n` +
      `2. Reason for reporting:\n\n` +
      `3. Any additional information:\n\n` +
      `Thank you for your report. We will investigate and respond within seven business days.`
    );
    window.location.href = `mailto:complaints@datenightai.com?subject=${subject}&body=${body}`;
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
      onClick={handleOutsideClick}
    >
      <div className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Report Content</h2>
        <p className="mb-4">
          If you've encountered content that you believe should be removed, please use the button below to report it.
        </p>
        <p className="mb-4">
          Your complaint will be thoroughly investigated, and we will provide a response within seven business days.
        </p>
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleReport}
            className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            Report Content
          </button>
          <button
            onClick={onClose}
            className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;

