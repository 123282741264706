import React from 'react';

const RegularButton = ({ onClick, children }) => {
  return (
    <button
      onClick={onClick}
      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-regular-button-text transition duration-150 ease-in-out bg-regular-button-bg border border-transparent rounded-md shadow-sm hover:bg-regular-button-bg-hover focus:outline-none focus:border-regular-button-bg-focus focus:shadow-outline-regular-button-bg active:bg-regular-button-bg-active"
    >
      {children}
    </button>
  );
};

export default RegularButton;

