const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";

export const getOrCreateUser = async (accessToken, auth0User, referralCode = '') => {
  const response = await fetch(API_DOMAIN + '/getOrCreateUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    body: JSON.stringify({auth0User, referralCode}),
  });
  return await response.json();
};

export const acceptTOS = async (accessToken) => {
  const response = await fetch(API_DOMAIN + '/acceptTOS', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
  });
  return await response.json();
};

export const fetchUsage = async (accessToken) => {
  const response = await fetch(`${API_DOMAIN}/usage`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch usage data');
  }
  return await response.json();
};

export const upgradePlan = async (accessToken, plan) => {
  try {
    const response = await fetch(`${API_DOMAIN}/updatePlan`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ plan }),
    });
    return await response.json();
  } catch (error) {
    console.error('[user.js] Error during plan upgrade', { error: error.message });
    throw error;
  }
};

export const saveMicrophoneStatus = async (accessToken, status) => {
  try {
    const response = await fetch(`${API_DOMAIN}/saveMicrophoneStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ status }),
    });
    if (!response.ok) {
      throw new Error('Failed to save microphone status');
    }
    return await response.json();
  } catch (error) {
    console.error('[user.js] Error saving microphone status', { error: error.message });
    throw error;
  }
};

export const updateUserPreferences = async (accessToken, preferences) => {
  try {
    const response = await fetch(`${API_DOMAIN}/preferences`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify(preferences),
    });
    return await response.json();
  } catch (error) {
    console.error('Error updating user preferences:', error);
    throw error;
  }
};