import React from 'react';
import { Navigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const ProtectedRoute = ({ component: Component, ...args }) => {
  const ComponentWithAuth = withAuthenticationRequired(Component, {
    onRedirecting: () => <Navigate to="/login" />,
  });

  return <ComponentWithAuth {...args} />;
};

export default ProtectedRoute;