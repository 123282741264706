const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";

export const generateImage = async (accessToken, selectedScene, currentSceneState) => {
  const apiURL = `${API_DOMAIN}/generateImage`;

  const response = await fetch(apiURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ scene: selectedScene, sceneState: currentSceneState }),
  });
  
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const imageData = await response.json();

  if (imageData.error) {
    throw new Error(imageData.error);
  }
  
  return {
    image: `data:image/jpeg;base64,${imageData.image}`,
    imageId: imageData.imageId
  };
};