import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: `Well sir, it looks like our meeting went long and we're the last two people in the office...`,
  prompt,
  actions,
  ttsConfig: {
    voiceId: '4688ae40-83e2-4eba-a709-b3bac2d432b5'
  },
  llmConfig: {
    llm: 'llama-3-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  limitReachedMessage: `Your time is up daddy. Please, please please upgrade your plan so we don't have to stop!`
}

export default config;
