import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useModalStore from '../stores/modalStore';
import MicrophoneModal from './modals/MicrophoneModal';
import AccountModal from './modals/AccountModal';
import UpgradePlanModal from './modals/UpgradePlan';
import TermsAndPoliciesModal from './modals/TermsAndPolicies';
import ReportModal from './modals/ReportModal';

const modalComponents = {
  microphone: MicrophoneModal,
  account: AccountModal,
  upgradePlan: UpgradePlanModal,
  termsAndPolicies: TermsAndPoliciesModal,
  report: ReportModal,
  // Add other modal components here
};

const ModalManager = () => {
  const modals = useModalStore((state) => state.modals);

  return (
    <AnimatePresence>
      {Object.entries(modals).map(([modalName, { isOpen, props }]) => {
        const ModalComponent = modalComponents[modalName];
        return ModalComponent && isOpen ? (
          <motion.div
            key={modalName}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9999
            }}
          >
            <ModalComponent {...props} />
          </motion.div>
        ) : null;
      })}
    </AnimatePresence>
  );
};

export default ModalManager;