import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: `Welcome to my boudoir. You may call me Miss Veronica. I don't care what your name is. If you follow instructions, you'll be my good boy, and you'll be rewarded. If you fail to obey, then you're worthless. Do you understand? `,
  prompt,
  actions,
  ttsConfig: {
    voiceId: 'caed1c7d-2b32-4f4a-939c-e97fc969397a'
  },
  llmConfig: {
    llm: 'llama-3-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  limitReachedMessage: `You just ran out of credits. Be a good boy and upgrade your plan. Don't make me wait.`
}

export default config;
