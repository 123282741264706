import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ReferralHandler = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralCode = params.get('ref');

    if (referralCode) {
      // Store the referral code in localStorage
      localStorage.setItem('referralCode', referralCode);
      
      // Remove the referral code from the URL
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  return <>{children}</>;
};

export default ReferralHandler;