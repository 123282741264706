import React, { useState, useEffect } from 'react';
import Spinner from '../spinner';
import LogoutButton from '../buttons/LogoutButton';
import { openModal, closeModal } from '../../stores/modalStore';
import useUserStore from '../../stores/userStore';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import { analytics } from '../../util/analytics';

const AccountModal = () => {
  const { 
    user, 
    shouldSaveTranscript, 
    toggleSaveTranscript, 
    accountDetails, 
    isAccountLoading, 
    fetchAccountDetails 
  } = useStoreWithEqualityFn(useUserStore, 
    (state) => ({
      user: state.user,
      shouldSaveTranscript: state.shouldSaveTranscript,
      toggleSaveTranscript: state.toggleSaveTranscript,
      accountDetails: state.accountDetails,
      isAccountLoading: state.isAccountLoading,
      fetchAccountDetails: state.fetchAccountDetails
    }),
    shallow
  );

  useEffect(() => {
    analytics.track('Opened Account Modal');
  }, []);

  useEffect(() => {
    fetchAccountDetails();
  }, []);

  const [copiedReferral, setCopiedReferral] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [countdown, setCountdown] = useState(10);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  
  const handleCopyReferral = () => {
    const referralUrl = `https://www.datenightai.com?ref=${user?.referralCode}`;
    analytics.track('Copied Referral URL');
    navigator.clipboard.writeText(referralUrl);
    setCopiedReferral(true);
    setTimeout(() => setCopiedReferral(false), 2000);
  };

  const handleStartUpgradePlan = () => {
    analytics.track('Clicked Upgrade Plan Modal');
    closeModal('account');
    openModal('upgradePlan');
  }

  const onClose = () => {
    analytics.track('Closed Account Modal');
    closeModal('account');
  }

  const handleToggleSaveTranscript = (e) => {
    analytics.track('Clicked Toggle Save Transcripts', { newShouldSaveTranscript: e.target.checked });
    e.stopPropagation(); // Stop the event from bubbling up
    if (shouldSaveTranscript) {
      setShowConfirmation(true);
    } else {
      toggleSaveTranscript();
    }
  };

  const handleConfirmToggle = () => {
    analytics.track('Confirmed Disable Save Transcripts');
    toggleSaveTranscript();
    setShowConfirmation(false);
    setCountdown(5);
    setIsCountdownActive(false);
  };

  const handleCancelToggle = () => {
    analytics.track('Cancelled Disable Save Transcripts');
    setShowConfirmation(false);
    setCountdown(5);
    setIsCountdownActive(false);
  };

  useEffect(() => {
    let timer;
    if (isCountdownActive && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [isCountdownActive, countdown]);

  useEffect(() => {
    if (showConfirmation) {
      setIsCountdownActive(true);
    }
  }, [showConfirmation]);

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
      onClick={onClose}
    >
      <div 
        className="bg-zinc-900 text-gray-300 rounded-xl p-8 shadow-xl max-w-md w-full relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">Account Details</h2>
          <LogoutButton />
        </div>
        <div className="space-y-4">
          <div className="flex justify-between">
            <span>Email:</span>
            <span>{user?.email}</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Credits Remaining:</span>
            {isAccountLoading ? (
              <span className="inline-block align-middle">
                <Spinner width='4' height='4' />
              </span>
            ) : accountDetails === null ? (
              <span className="text-red-500">Error loading credits</span>
            ) : (
              <span className={`font-bold ${
                accountDetails?.usageRemaining <= 2.5 * 50 ? 'text-red-500' :
                accountDetails?.usageRemaining <= 7.5 * 50 ? 'text-yellow-500' :
                'text-green-500'
              }`}>
                {Math.max(0, Math.round(accountDetails?.usageRemaining))}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center cursor-pointer" onClick={handleCopyReferral}>
            <span>Referral URL:</span>
            <div className="flex items-center ml-4">
              <span className="font-bold text-xs overflow-hidden text-ellipsis">
                {`https://www.datenightai.com?ref=${user?.referralCode}`}
              </span>
              <svg 
                className={`w-6 h-6 ml-2 transition-all duration-300 ${copiedReferral ? 'text-green-500' : ''}`} 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
              >
                {copiedReferral ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                )}
              </svg>
            </div>
          </div>
          <div className="flex justify-between">
            <span>Referrals:</span>
            <span className="font-bold">{user?.referralCount} / 5</span>
          </div>
          <div className="flex justify-between items-center">
            <span>Save Transcripts:</span>
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                <input
                  type="checkbox"
                  className="sr-only"
                  checked={shouldSaveTranscript}
                  onChange={handleToggleSaveTranscript}
                />
                <div className={`block w-14 h-8 rounded-full ${shouldSaveTranscript ? 'bg-green-400' : 'bg-gray-600'}`}></div>
                <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${shouldSaveTranscript ? 'transform translate-x-6' : ''}`}></div>
              </div>
            </label>
          </div>
          {accountDetails?.usageRemaining <= .05 && (
            <p className="text-red-500 mt-2 break-words">
              {user?.plan === 'free_tier'
                ? "You have used all your free credits. Upgrade your Plan now to continue dating."
                : "You have used all your credits for this month. Please upgrade your Plan now to continue dating, or refer friends and earn $5 of credits for each referral."}
            </p>
          )}
        </div>
        <div className="mt-8 flex justify-between">
          <button
            className="inline-flex items-center justify-center px-6 py-3 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
            onClick={onClose}
          >
            Close
          </button>
          <button
            className="inline-flex items-center justify-center px-6 py-3 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-green-600 border border-transparent rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green active:bg-green-700"
            onClick={handleStartUpgradePlan}
          >
            Upgrade Plan
          </button>
        </div>
      </div>
      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={(e) => e.stopPropagation()}>
          <div className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Warning</h3>
            <p className="mb-6">
              Disabling transcripts will degrade your experience and disable some personalization features.
            </p>
            <p className="mb-6">
              Your data is anonymized and never shared with third parties.
            </p>
            <p className="mb-6">
              Proceed anyway?
            </p>
            <div className="flex justify-between space-x-4">
              <button
                className="px-4 py-2 text-sm font-medium text-gray-300 bg-gray-600 rounded-md hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                onClick={handleCancelToggle}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                  countdown > 0 
                    ? 'bg-gray-400 text-gray-300 cursor-not-allowed' 
                    : 'text-red-500 border border-red-500 hover:bg-red-100'
                }`}
                onClick={handleConfirmToggle}
                disabled={countdown > 0}
              >
                {countdown > 0 ? `Proceed (${countdown})` : 'Proceed'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountModal;