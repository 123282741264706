import React, { useState, useRef, useEffect } from 'react';
import useUserStore from '../../stores/userStore';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../spinner';
import { closeModal } from '../../stores/modalStore';
import { useLocation } from 'react-router-dom';
import { analytics } from '../../util/analytics';

const UpgradePlanModal = () => {
  const { user, upgradePlan, isLimitReached } = useUserStore();
  const { isAuth0Loading } = useAuth();
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [error, setError] = useState(null);
  const [showCancelView, setShowCancelView] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [countdown, setCountdown] = useState(15);
  const modalRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    analytics.track('Opened Upgrade Plan Modal');
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && canClose) {
        analytics.track('Closed Upgrade Plan Modal');
        closeModal('upgradePlan');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [canClose]);

  useEffect(() => {
    const isInStory = location.pathname.includes('/story');
    if (isInStory && isLimitReached) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            setCanClose(true);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    } else {
      setCanClose(true);
      setCountdown(0);
    }
  }, [location, isLimitReached]);

  const handleUpgrade = async (plan) => {
    analytics.track('Clicked Upgrade Plan', { plan });
    setLoadingPlan(plan);
    setError(null);
    try {
      const result = await upgradePlan(plan);
      if (result.redirectURL) {
        window.location.href = result.redirectURL;
      } else {
        // show an error
      }
    } catch (error) {
      setError('Failed to upgrade plan. Please try again.');
    } finally {
      setLoadingPlan(null);
    }
  };

  const isPlanDisabled = (plan) => user.plan === plan;

  const isPaidPlan = user.plan !== 'free_trial';

  const handleCancelClick = () => {
    analytics.track('Clicked Cancel Subscription');
    setShowCancelView(true);
  };

  const handleCloseModal = () => {
    if (canClose) {
      analytics.track('Closed Upgrade Plan Modal');
      closeModal('upgradePlan');
    }
  };

  if (isAuth0Loading) {
    return <Spinner />;
  }

  if (showCancelView) {
    return (
      <div 
        className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
      >
        <div ref={modalRef} className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
          <h2 className="text-2xl font-bold mb-4">Cancel Subscription</h2>
          <p className="mb-4">To cancel or update your subscription, use your Epoch member id and Epoch email with Epoch:</p>
          <p className="mb-2"><strong>Epoch Member ID:</strong> {user.epoch_member_id}</p>
          <p className="mb-4"><strong>Epoch Email:</strong> {user.epoch_email}</p>
          <a 
            href="https://epoch.com" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-400 underline"
          >
            Go to Epoch.com
          </a>
          <div className="mt-6 flex justify-between">
            <button
              className="px-4 py-2 bg-gray-600 rounded-md hover:bg-gray-500 transition-colors"
              onClick={() => setShowCancelView(false)}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
    >
      <div ref={modalRef} className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Upgrade to Continue</h2>
        <p className="mb-2 text-sm italic text-gray-400">10 credits ≈ 1 minute of conversation.</p>
        <p className="mb-4 text-sm italic text-gray-400">Remaining credits carry over when changing plans. New monthly charges apply. Change anytime.</p>
        
        <div className="mb-6 p-4 bg-zinc-800 rounded-md">
          <h3 className="text-xl font-semibold mb-2">Why Upgrade?</h3>
          <ul className="list-disc list-inside space-y-2">
            <li>Extended conversations.</li>
            <li>Early access to exciting new Experiences.</li>
            <li>Priority for new feature requests.</li>
          </ul>
        </div>
        <div className="space-y-4 mb-6">
          {['I', 'II', 'III'].map((plan) => (
            <button
              key={plan}
              className={`w-full p-4 rounded-md ${loadingPlan === plan ? 'bg-indigo-600' : 'bg-zinc-800'} ${isPlanDisabled(plan) ? 'opacity-50 cursor-not-allowed' : 'hover:bg-indigo-500'} transition-colors relative`}
              onClick={() => !isPlanDisabled(plan) && handleUpgrade(plan)}
              disabled={isPlanDisabled(plan) || loadingPlan !== null}
            >
              {loadingPlan === plan ? (
                <div className="flex justify-center items-center h-full mt-1 mb-1">
                  <Spinner />
                </div>
              ) : (
                <div className="flex flex-col justify-center h-full mt-1 mb-1">
                  <div className="flex justify-between items-center">
                    <span className={`w-24 text-left ${plan === 'I' ? 'text-amber-300' : plan === 'II' ? 'text-gray-400' : 'text-yellow-400'}`}>
                      {plan === 'I' ? 'Basic' : plan === 'II' ? 'Advanced' : 'Elite'}
                    </span>
                    <span className="text-gray-400">
                      {plan === 'I' ? '500' : plan === 'II' ? '1050' : '1650'} credits
                    </span>
                    <span className="w-24 text-right">
                      ${plan === 'I' ? '9.99' : plan === 'II' ? '19.99' : '29.99'}/mo
                      {isPlanDisabled(plan) && <span className="ml-2">✓</span>}
                    </span>
                  </div>
                  <div>
                    {['I', 'II', 'III'].indexOf(plan) < ['I', 'II', 'III'].indexOf(user.plan)
                    }
                    {['I', 'II', 'III'].indexOf(plan) > ['I', 'II', 'III'].indexOf(user.plan)
                    }
                  </div>
                  {plan !== 'I' && (
                    <div className="absolute top-0 right-0 bg-indigo-600 text-white text-[10px] font-bold px-1.5 py-0.5 rounded-bl-sm rounded-tr-sm">
                      {plan === 'II' ? '5% off' : '10% off'}
                    </div>
                  )}
                </div>
              )}
            </button>
          ))}
        </div>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="flex justify-between items-center">
          <button
            className={`px-4 py-2 ${canClose ? 'bg-gray-600 hover:bg-gray-500' : 'bg-gray-400 cursor-not-allowed'} rounded-md transition-colors`}
            onClick={handleCloseModal}
            disabled={!canClose || loadingPlan !== null}
          >
            {canClose ? 'Close' : `Please wait... (${countdown}s)`}
          </button>
          {isPaidPlan && (
            <button
              className="px-4 py-2 bg-gray-600 rounded-md hover:bg-gray-500 transition-colors"
              onClick={handleCancelClick}
            >
              Cancel Subscription
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModal;
