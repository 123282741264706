import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { PersonaProvider } from './contexts/PersonaClientContext';
import ProtectedRoute from './components/util/ProtectedRoute';
import TermsOfServiceModal from './components/modals/ToS';
import ReferralHandler from './components/util/ReferralHandler';
import Main from './pages/Main';
import Login from './pages/Login';
import Story from './components/views/Story';
import ModalManager from './components/ModalManager';
import useUserStore from './stores/userStore';

function AppContent() {
  const showTOS = useUserStore(state => state.showTOS);
  const acceptTOS = useUserStore(state => state.acceptTOS);

  return (
    <>
      {showTOS && <TermsOfServiceModal onAccept={acceptTOS} />}
      <ModalManager />
      <Routes>
        <Route path="/login" element={<Login key="login" />} />
        <Route path="/story" element={<ProtectedRoute component={Story} key="story" />} />
        <Route path="/" element={<ProtectedRoute component={Main} key="main" />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
        <AuthProvider>
          <PersonaProvider>
            <ReferralHandler>
              <AppContent />
            </ReferralHandler>
          </PersonaProvider>
        </AuthProvider>
    </Router>
  );
}

export default App;