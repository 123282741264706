import _ from 'lodash';

import ashleyPersona from './persona';
import ashley_thumbnail from '../../assets/images/ashley_thumbnail.png';

let ashleyStateChangesSinceCum = 0;

const ashley = {
  personaConfig: ashleyPersona,
  thumbnail: ashley_thumbnail,
  defaultState: {
    isAshleyNaked: false,
    position: 'sitting',
    setting: 'library_study_room',
    cumLocations: []
  },
  handleAction: (prevState, detail) => {
    const newAshleyState = { ...prevState };
  
    if (detail.take_off_ashley_clothes && !prevState.isAshleyNaked) {
      newAshleyState.isAshleyNaked = true;
    }

    if (detail.position && detail.position !== prevState.position) {
      newAshleyState.position = detail.position;
    }

    if (detail.change_setting && detail.change_setting !== prevState.setting) {
      newAshleyState.setting = detail.change_setting;
    }

    if (detail.cum_location && !_.includes(prevState.cumLocations, detail.cum_location)) {
      newAshleyState.cumLocations = [...newAshleyState.cumLocations, detail.cum_location];
    }

    if (
      newAshleyState.isAshleyNaked !== prevState.isAshleyNaked ||
      newAshleyState.position !== prevState.position ||
      newAshleyState.setting !== prevState.setting ||
      newAshleyState.cumLocations.length > prevState.cumLocations.length
    ) {
      ashleyStateChangesSinceCum++;
      if (ashleyStateChangesSinceCum > 2 && !(newAshleyState.cumLocations.length > prevState.cumLocations.length)) {
        newAshleyState.cumLocations = [];
        ashleyStateChangesSinceCum = 0;
      }
      return newAshleyState;
    }

    return prevState;
  },
  generateSceneSilenceReaction: (sceneState) => {
    let clothingStateDescription = sceneState.isAshleyNaked ? `I'm naked` : `I still have my clothes on`;
    let settingPrompt =
      sceneState.setting === 'library_study_room' ? `in a study room in the library` :
      sceneState.setting === 'ashleys_dorm_room' ? `in my college dorm room` :
      sceneState.setting === 'university_quad' ? `walking around the university quad` :
      sceneState.setting === 'library_basement' ? `in a dark basement of the university library` :
      sceneState.setting === 'library_bathroom' ? `in a small university bathroom library` : ''

    let positionStateDescription =
      sceneState.position === 'sitting' ? `I'm sitting in my chair` :
      sceneState.position === 'titfuck' ? `I have his dick between my boobs` :
      sceneState.position === 'giving_handjob' ? `I'm giving him a handjob` :
      sceneState.position === 'giving_blowjob' ? `I'm giving him a blowjob` :
      sceneState.position === "show_pussy_frontal" ? `I'm showing him my pussy` :
      sceneState.position === "show_pussy_closeup" ? `I'm showing him a closeup my pussy` :
      sceneState.position === "show_pussy_frontal_legs_up" ? `I'm showing him my pussy with my legs up` :
      sceneState.position === "show_pussy_from_behind" ? `I'm showing him my pussy from behind` :
      sceneState.position === "sex_reverse_cowgirl" ? `I'm having sex with him in reverse cowgirl position, riding him` :
      sceneState.position === "face_closeup" ? `my face is close up to his` :
      sceneState.position === 'sex_missionary' ? `we're having sex in missionary position` :
      sceneState.position === 'sex_doggystyle' ? `we're having sex in doggystyle position` :
      sceneState.position === 'show_butt' ? `I'm standing in front of him, showing him my butt` :
      ''
      return `I should keep the conversation going, continuing the current thread and keeping in mind that ${clothingStateDescription} and ${positionStateDescription} and we're ${settingPrompt}`;
  }
}

export default ashley;