import React, { useState, useRef, useEffect } from 'react';
import useUserStore from '../../stores/userStore';
import { useAuth } from '../../contexts/AuthContext';
import Spinner from '../spinner';

const TermsOfServiceModal = () => {
  const { acceptTOS } = useUserStore();
  const { isAuth0Loading } = useAuth();
  const [isAtBottom, setIsAtBottom] = useState(false);
  const tosContentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = tosContentRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setIsAtBottom(true);
    }
  };

  useEffect(() => {
    const contentElement = tosContentRef.current;
    contentElement.addEventListener('scroll', handleScroll);
    return () => contentElement.removeEventListener('scroll', handleScroll);
  }, []);

  const handleAccept = async () => {
    setIsLoading(true);
    await acceptTOS();
    setIsLoading(false);
  };

  if (isAuth0Loading) {
    return <Spinner />;
  }

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
    >
      <div className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4">Terms of Service</h2>
        <div 
          ref={tosContentRef}
          className="h-96 overflow-y-auto mb-4 p-4 bg-zinc-800 rounded"
          style={{ scrollBehavior: 'smooth' }}
        >
          {/* Terms of Service content */}
          <h3 className="text-xl font-semibold mb-4">1. Age Restriction and Legal Compliance</h3>
          <p className="mb-4">1.1 You must be at least 18 years old to use this service. By accepting these Terms, you confirm that you are of legal age to view and interact with adult content in your jurisdiction.</p>
          <p className="mb-4">1.2 You agree to comply with all applicable local, state, national, and international laws and regulations while using our service.</p>

          <h3 className="text-xl font-semibold mb-4">2. User Responsibility</h3>
          <p className="mb-4">2.1 You are solely responsible for any content you generate, request, or produce using our AI language and image models, including any adult or potentially illegal content.</p>
          <p className="mb-4">2.2 By interacting with our AI models, you acknowledge that you are generating content and bear full responsibility for that content, even if it's produced by AI based on your inputs.</p>
          <p className="mb-4">2.3 You understand and agree that you do not own any content generated through our service. All rights, title, and interest in and to the generated content belong exclusively to us.</p>

          <h3 className="text-xl font-semibold mb-4">3. Prohibited Content and Activities</h3>
          <p className="mb-4">3.1 You agree not to use our service to generate, request, or distribute any content that is illegal, abusive, threatening, harmful, tortious, defamatory, vulgar, obscene, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</p>
          <p className="mb-4">3.2 Specifically prohibited content includes, but is not limited to: child exploitation material, non-consensual intimate imagery, extreme violence, illegal activities, hate speech, and harassment.</p>
          <p className="mb-4">3.3 You are strictly prohibited from engaging in any activities that attempt to reverse-engineer, decompile, disassemble, or hack our service, including but not limited to:</p>
          <ul className="list-disc list-inside mb-4 ml-4">
            <li>Attempting to access or manipulate our servers or backend systems</li>
            <li>Trying to extract our AI models or training data</li>
            <li>Launching any form of automated attack or denial of service against our infrastructure</li>
            <li>Using bots, scrapers, or other automated tools to interact with our service</li>
          </ul>

          <h3 className="text-xl font-semibold mb-4">4. Intellectual Property</h3>
          <p className="mb-4">4.1 All content generated using our service, including but not limited to text, images, and audio, is the sole property of our company. You do not retain any ownership rights to the generated content.</p>
          <p className="mb-4">4.2 We grant you a limited, non-exclusive, non-transferable license to view and use the generated content for personal, non-commercial purposes only.</p>
          <p className="mb-4">4.3 You may not sell, license, sublicense, distribute, or otherwise commercialize any content generated through our service without our explicit written permission.</p>

          <h3 className="text-xl font-semibold mb-4">5. Limitation of Liability</h3>
          <p className="mb-4">5.1 We are not responsible for any content generated by our AI models based on your inputs. You agree to indemnify and hold us harmless from any claims resulting from your use of our service.</p>
          <p className="mb-4">5.2 We do not guarantee the accuracy, completeness, or appropriateness of any generated content. You use the generated content at your own risk.</p>

          <h3 className="text-xl font-semibold mb-4">6. Service Modifications and Monitoring</h3>
          <p className="mb-4">6.1 We reserve the right to modify, suspend, or discontinue our service at any time without notice.</p>
          <p className="mb-4">6.2 We may, but are not obligated to, monitor your use of the service and any content generated. We reserve the right to remove or modify any content that violates these Terms or that we find objectionable for any reason.</p>

          <h3 className="text-xl font-semibold mb-4">7. Termination</h3>
          <p className="mb-4">7.1 We may terminate or suspend your access to our service immediately, without prior notice or liability, for any reason, including breach of these Terms.</p>
          <p className="mb-4">7.2 Upon termination, your right to use the service will immediately cease, and we may delete or remove any content associated with your account.</p>

          <h3 className="text-xl font-semibold mb-4">8. Changes to Terms</h3>
          <p className="mb-4">8.1 We reserve the right to modify these Terms at any time. Your continued use of the service after any changes constitutes acceptance of those changes.</p>
          <p className="mb-4">8.2 It is your responsibility to review these Terms periodically for updates.</p>

          <h3 className="text-xl font-semibold mb-4">9. Governing Law and Jurisdiction</h3>
          <p className="mb-4">9.1 These Terms shall be governed by and construed in accordance with the laws of State of Wyoming and the United States of America, without regard to its conflict of law provisions.</p>
          <p className="mb-4">9.2 Any disputes arising from or relating to these Terms or your use of the service shall be subject to the exclusive jurisdiction of the state and federal courts located in Wyoming, United States of America.</p>

          <p className="mt-6 font-bold">By using our service, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree to these Terms, you must not use our service.</p>
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <button
            className={`w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm ${
              isAtBottom
                ? 'bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700'
                : 'bg-gray-600 cursor-not-allowed'
            }`}
            onClick={handleAccept}
            disabled={!isAtBottom}
          >
            {isAtBottom ? 'I am at least 18 years old and I accept the Terms of Service.' : 'Please read the entire Terms of Service.'}
          </button>
        )}
      </div>
    </div>
  );
};

export default TermsOfServiceModal;
