import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="auth.datenightai.com"
    clientId="aGMq26SX8TbJ2duQ5HeaD11SsAEZeKKA"
    legacySameSiteCookie={false}
    authorizationParams={{
      audience: "datenightai-api-identifier",
      redirect_uri: window.location.origin + '/login',
      scope: "openid profile email offline_access",
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      legacySameSiteCookie: false,
      prompt: 'login',
    }}
    cacheLocation="localstorage"
    useRefreshTokensFallback={true}
    useRefreshTokens={true}
    onRedirectCallback={(appState, user, error) => {
      if (error) {
        console.error('Auth0 error:', error);
        // You can add custom error handling here, e.g., redirecting to an error page
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    }}
  >
    <App />
  </Auth0Provider>
);