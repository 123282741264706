import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: 'I could really use a break from studying...',
  prompt,
  actions,
  ttsConfig: {
    voiceId: 'b29de159-640e-47e7-ba4d-61e5b93b6efb'
  },
  llmConfig: {
    llm: 'llama-3-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  limitReachedMessage: `We have to stop now. We can keep going if you upgrade your plan.`
}

export default config;
