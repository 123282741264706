import React, { useState, useEffect } from 'react';
import { analytics } from '../../util/analytics';

const MicrophoneModal = ({ onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    analytics.track('Opened Microphone Modal');
  }, []);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const checkIfFirefox = () => {
      setIsFirefox(navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
    };

    checkIfMobile();
    checkIfFirefox();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const onModalClose = React.useCallback(() => {
    window.location.reload();
  }, []);
  
  const handleOutsideClick = React.useCallback((e) => {
    if (e.target === e.currentTarget) {
      onModalClose();
    }
  }, [onModalClose]);



  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
      onClick={handleOutsideClick}
    >
      <div className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
        <div className="flex items-center mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mr-2 text-indigo-500" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z" clipRule="evenodd" />
          </svg>
          <h2 className="text-2xl font-bold">Enable Microphone</h2>
        </div>
        <p className="mb-4">
          To fully enjoy your date night experience, you need to enable your microphone. Speaking with your date is the primary mode of interaction.
        </p>
        <p className="mb-4">
          Please allow microphone access for the site in your settings.
        </p>
        <div className="flex flex-col space-y-4">
          <button
            onClick={onModalClose}
            className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
          >
            I understand
          </button>
        </div>
      </div>
      {!isMobile && (
        <div className={`fixed top-0 ${isFirefox ? 'left-32' : 'left-32'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-indigo-500 transform -rotate-45" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
          <p className="text-m text-indigo-500 mt-1 text-center ml-2">Click here to<br />enable microphone</p>
        </div>
      )}
    </div>
  );
};

export default MicrophoneModal;
