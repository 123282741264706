import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import useUserStore from '../stores/userStore';

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const {
    isLoading: isAuth0Loading,
    error,
    user: auth0User,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    isAuthenticated,
  } = useAuth0();

  const setAccessTokenStore = useUserStore(state => state.setAccessToken);
  const initializeUser = useUserStore(state => state.initializeUser);
  const fetchAccountDetails = useUserStore(state => state.fetchAccountDetails);

  const [accessToken, setAccessToken] = useState(null);

  const navigate = useNavigate();
  
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const referralCode = localStorage.getItem('referralCode');

        if (!isAuth0Loading && isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          setAccessTokenStore(accessToken);
          setAccessToken(accessToken);

          await initializeUser(auth0User, referralCode);
          localStorage.removeItem('referralCode');

          fetchAccountDetails();

          if (window.location.pathname === '/login') {
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
      }
    };

    checkAuthentication();
  }, [isAuthenticated, isAuth0Loading, auth0User, getAccessTokenSilently, navigate]);

  const contextValue = {
    isAuth0Loading,
    error,
    loginWithRedirect,
    logout,
    accessToken
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};