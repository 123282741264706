import React from 'react';
import { useUser } from '../../contexts/AuthContext';
import { closeModal } from '../../stores/modalStore';

const TermsAndPoliciesModal = () => {
  const handleOutsideClick = (e) => {
    if (e.target === e.currentTarget) {
      closeModal('termsAndPolicies');
    }
  };

  const onClose = () => {
    closeModal('termsAndPolicies');
  };

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center z-50 bg-main-bg bg-opacity-75"
      onClick={handleOutsideClick}
    >
      <div className="bg-zinc-900 text-gray-300 rounded-xl p-6 shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Support, Terms, & Policies</h2>
        <div className="flex flex-col space-y-4 mb-6">
          <a 
            href="/tos.html" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-400 hover:text-indigo-300 underline"
          >
            Terms of Service
          </a>
          <a 
            href="https://epoch.com" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-400 hover:text-indigo-300 underline"
          >
            Billing Support
          </a>
          <a 
            href="/privacypolicy.html" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-400 hover:text-indigo-300 underline"
          >
            Privacy Policy
          </a>
          <a 
            href="/2257.html" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-indigo-400 hover:text-indigo-300 underline"
          >
            18 U.S.C. 2257 Record-Keeping Requirements Exemption Statement
          </a>
          <a 
            href="mailto:support-alt@datenightai.com?subject=DateNight%20support" 
            className="text-indigo-400 hover:text-indigo-300 underline"
          >
            Contact Us
          </a>
        </div>
        <button
          onClick={onClose}
          className="w-full inline-flex items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out border border-transparent rounded-md shadow-sm bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsAndPoliciesModal;
