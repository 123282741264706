import { create } from 'zustand';

const defaultModalState = {
  microphone: { isOpen: false, props: {} },
  account: { isOpen: false, props: {} },
  upgradePlan: { isOpen: false, props: {} },
  termsAndPolicies: { isOpen: false, props: {} },
  report: { isOpen: false, props: {} },
};

const useModalStore = create((set) => {
  return {
    modals: defaultModalState,
    openModal: (modalName, props = {}) => {
      set((state) => ({
        modals: { ...state.modals, [modalName]: { isOpen: true, props } },
      }));
    },
    closeModal: (modalName) => {
      set((state) => ({
        modals: { ...state.modals, [modalName]: { isOpen: false, props: {} } },
      }));
    },
  };
});

// Export a function to directly set the modal state
export const setModalState = (modalName, isOpen, props = {}) => {
  useModalStore.setState((state) => ({
    modals: {
      ...state.modals,
      [modalName]: { isOpen, props }
    }
  }));
};

// Export a function to open a modal
export const openModal = (modalName, props = {}) => {
  setModalState(modalName, true, props);
};

// Export a function to close a modal
export const closeModal = (modalName) => {
  setModalState(modalName, false);
};

export const getModalStates = () => {
  const modalState = useModalStore.getState().modals;
  return Object.keys(modalState).reduce((acc, modalName) => {
    acc[modalName] = modalState[modalName].isOpen;
    return acc;
  }, {});
};



export default useModalStore;