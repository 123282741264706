import prompt from './prompt';
import actions from './actions';

const config = {
  initialMessage: 'So, what brings you to my office today?',
  prompt,
  actions,
  ttsConfig: {
    voiceId: '3d3bb7f0-ae01-4721-b435-822a0880a89b'
  },
  llmConfig: {
    llm: 'llama-3-70b',
    temperature: 0.9,
    repetitionPenalty: 0.5,
  },
  interjections: {
    enabled: true,
    minWait: 5000,
    maxWait: 7500,
    thought: `There was just a pause; I should keep the conversation going.`
  },
  limitReachedMessage: `That's all for our time today. Upgrade your plan now so we can continue the physical exam.`
}

export default config;

