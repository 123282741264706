import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import useUserStore from '../../stores/userStore';
import Spinner from '../spinner';
import { analytics } from '../../util/analytics';

const LogoutButton = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const setUser = useUserStore(state => state.setUser);
  const setAccessToken = useUserStore(state => state.setAccessToken);
  const [wasClicked, setWasClicked] = useState(false);

  const handleLogout = async () => {
    try {
      analytics.track('Clicked Logout Button');
      await logout({
        returnTo: window.location.origin,
        federated: true,
      });

      // Clear user data from the store
      setUser(null);
      setAccessToken(null);

      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <button
      onClick={() => {
        setWasClicked(true);
        handleLogout();
      }}
      className="text-gray-400 hover:text-white font-medium text-sm flex items-center justify-center"
    >
      {wasClicked ? (
        <Spinner width="4" height="4" />
      ) : (
        <>
          Logout
          <svg
            className="w-4 h-4 ml-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            ></path>
          </svg>
        </>
      )}
    </button>
  );
};

export default LogoutButton;